import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {actionCreators} from '../store/PrepareConversation';
import { Redirect } from 'react-router'
import BusyIndicator from "./BusyIndicator";
import "./assets/Chat.css"

class PrepareProviderConversation extends Component {
    componentDidMount() {
        this.setState({isLoading: true});
        this.props.PrepareProviderConversation(this.props.id);
    }

    componentDidUpdate(prevProps) {
        if (!this.state.isLoading &&
            (prevProps.id !== this.props.id || !this.props.isReady))
        {
            this.setState({isLoading: true});
            this.props.PrepareProviderConversation(this.props.id);
        }
    }
    
    render() {
        let to = `/chat/${this.props.sid}/message`;
        return this.props.isReady
            ? <Redirect to={to} />
            : this.renderBusyState();
    }

    renderBusyState(){
        return (
            <div className={"messages_container im-container"}>
                <BusyIndicator />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const id = ownProps.match.params.id;
    const prepareConversationState = state.prepareConversation;
    return { id: id, isReady: prepareConversationState.isReady, sid: prepareConversationState.sid };
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(PrepareProviderConversation);