import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import './assets/ChatHeader.css';
import { Redirect } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCheck, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { actionCreators } from '../store/ChatStore';
import { bindActionCreators } from 'redux';
import { isProviderIdentity } from '../store/selectors';
import DeleteChatPopUp from './PopUps/DeleteChatPopUp'

class ChatHeader extends Component {
    
    constructor(props) {
        super(props);
        this.state = {isClosed: null};
        this.onClose = this.onClose.bind(this);
    }
    
    render() {
        const isClosed = this.state.isClosed;
        return isClosed
            ? renderRedirect()
            :  this.renderHeader();
    }

    onClose = event => {
        this.setState({isClosed: 1});
    };

    onDelete = event => {
        this.props.openDeleteChatDialog(this.props.conversationSid);
    };
    
    renderHeader() {
        const text = this.props.headerText;
        return (
            <Fragment>
                <div className={'chat-header'}>
                    <div className={'title-container'}>
                        <span >{text}</span>
                    </div>
                    <div className={'buttons-container'}>
                        {this.renderInviteButton(this.props)}
                        {this.renderDeleteButton()}
                    </div>
                </div>
                {this.renderHorizontalLine()}
                <DeleteChatPopUp/>
            </Fragment>
        );
    }

    renderInviteButton(props) {
        const url = this.constructInviteLink(props);
        let buttonClassNames = 'invite-button';
            
        buttonClassNames = buttonClassNames + (props.canClose ? '' : ' button-with-margin-20');

        return (
            <button className={buttonClassNames}>
                <a target="_top" href={ url }>View Invite</a>
            </button>
        );
    }

    constructInviteLink(props) {
        if(props.isProviderIdentity) {
            return  props.providerNetworkUrl;
        }
        
        var subscriberLink = props.subscriberInviteLink
            .replace('{0}', props.fixxBookUrl)
            .replace('{1}', props.authenticationServerUrl)
            .replace('{2}', props.scUserId)
            .replace('{3}', encodeURIComponent(props.subscriberNetworkUrl))

        return subscriberLink;

    }

    renderCloseButton() {
        const canClose = this.props.canClose;
        if (canClose) {
            return (
                <button onClick={this.onClose} className={"close-button"}>
                    <FontAwesomeIcon icon={["fas", "times"]} />
                </button>
            );
        }
    }

    renderDeleteButton() {
        const canClose = this.props.canClose;
        if (canClose) {
            return (
                <button onClick={this.onDelete} className={"delete-button"}>
                    <FontAwesomeIcon icon={["fas", "trash-alt"]} />
                </button>
            );
        }
    }

    renderHorizontalLine() {
        const canClose = this.props.canClose;
        if (canClose) {
            return (
                <hr className={'chat-header'} />
            );
        }
    }
}


function renderRedirect() {
    const to = "/chat/";
    return (
        <Redirect to={to} />
    );
}

function mapStateToProps(state, ownProps) {
    return {
        isProviderInNetwork: state.chatStore.isProviderInNetwork,
        isProviderIdentity: isProviderIdentity(state),
        subscriberCompanyId: state.chatStore.subscriberCompanyId,
        providerCompanyId: state.chatStore.providerCompanyId,
        subscriberNetworkUrl: state.chatStore.settings.subscriberNetworkUrl,
        providerNetworkUrl: state.chatStore.settings.providerNetworkUrl,
        subscriberInviteLink: state.chatStore.settings.inviteLink,
        fixxBookUrl: state.chatStore.settings.fixxbookUrl,
        authenticationServerUrl: state.chatStore.settings.authenticationServerUrl,
        scUserId: state.chatStore.scUserId
    };
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ChatHeader);
