import { getIdentityChatName } from '../impl/ChatClientProxy'
import {
    addConversationType, updateConversationType, chatClientStateChangedType, initChatClientType,
    receiveSidType, removeConversationType,
    requestSidType, chatClientInitializedType,
    initChatConversationType, chatConversationInitializedType, messageAddedType,
    setConversationFilterType, setcurrentConversationIdType, 
    setMessageConsumedTimestampType, setLayoutTimestampType,
    setUnauthorizedType, receiveProviderInNetworkType, resetProviderInNetworkType, setProviderAndSubscriberIds,
    openDeleteChatPopup, closeDeleteChatPopup, confirmChatDelete, setProviderBlockedStatus, showUnblockedMessage,
    hideUnblockedMessage, loadNewMessagesType, setNetworkStatusesType
} from "./ActionTypes";

import * as ActionsImpl from '../impl/ChatStoreActions'

const initialState = {
    conversationIds: [],
    conversationByIds: {},
    token: '',
    identity: '',
    isReady: false,
    chatClient: null,
    chatFilter: null,
    chatFilterText: "",
    currentConversationId: null,
    isBlockedProvider: false,
    showUnblockedMessage: false
};

export const actionCreators = {

    addConversation: ActionsImpl.addConversationPayload,
    
    removeConversation: ActionsImpl.removeConversationPayload,

    initChatClient: () => ActionsImpl.initChatClient,
     
    initConversation: ActionsImpl.initConversationFunction,
    
    sendMessage: ActionsImpl.sendMessageFunction,

    setConversationFilter: ActionsImpl.setConversationFilterPayload,
    
    updateCurrentConversationId: ActionsImpl.updateCurrentConversationIdFunction,

    updateMessageConsumedTimestamp: ActionsImpl.updateMessageConsumedTimestamp,

    updateLayoutTimestamp: ActionsImpl.updateLayoutTimestampFunction,

    openDeleteChatDialog: ActionsImpl.openDeleteChatPopupFunction,

    closeDeleteChatDialog: ActionsImpl.closeDeleteChatPopupFunction,

    deleteConversation: ActionsImpl.deleteConversationFunction,

    unblockProvider: ActionsImpl.unblockProvider,

    hideUnblockedProviderMessage: ActionsImpl.hideUnblockProviderMessageFunction
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case addConversationType: {
            const twConversation = action.twConversation;
            return {
                ...state,
                conversationIds: [...state.conversationIds, twConversation.sid],
                conversationByIds: {
                    ...state.conversationByIds,
                    [twConversation.sid]: { 
                        conversation: twConversation, messages: [], 
                        isReady: false, isLoading: false, 
                        name: getIdentityChatName(twConversation, state.identity),
                        isInitiatedBySubscriber: action.isInitiatedBySubscriber
                    }
                }
            };
        }
        case removeConversationType: {
            const id = action.id;
            let index = state.conversationIds.indexOf(id);
            if (index === -1)
                return state;

            return {
                ...state,
                conversationIds: [
                    ...state.conversationIds.slice(0, index),
                    ...state.conversationIds.slice(index + 1)],
                conversationByIds: Object.fromEntries(Object.entries(state.conversationByIds)
                    .filter(([k, v]) => k !== id))
            };
        }

        case updateConversationType: {
            const twConversation = action.twConversation.conversation;
            const storageConversation = state.conversationByIds[twConversation.sid];

            return {
                ...state,
                conversationByIds: {
                    ...state.conversationByIds,
                    [twConversation.sid]: {
                        ...storageConversation,
                        ...twConversation
                    }
                }
            };
        }

        case receiveSidType:
            return {
                ...state,
                identity: action.payload.identity,
                token: action.payload.token,
                userId: action.payload.userId,
                scUserId: action.payload.scUserId,
                settings: action.payload.settings
            };

        case setUnauthorizedType:
            ActionsImpl.redirectToLogin();
            return {
                ...state,
                unauthorized: true,
            };
            
        case chatClientStateChangedType:
            return {
                ...state, 
                isReady: action.status === 'connected'
            };
            
        case chatClientInitializedType:
            return {
                ...state,
                chatClient: action.chatClient
            };
            
        case initChatConversationType: {
            const twConversation = action.twConversation;
            const storageConversation = state.conversationByIds[twConversation.sid];
            const isInitiatedBySubscriber = storageConversation ? storageConversation.isInitiatedBySubscriber : false;
            return {
                ...state,
                conversationByIds: {
                    ...state.conversationByIds,
                    [twConversation.sid]: {
                        ...storageConversation,
                        conversation: twConversation, messages: [],
                        isReady: false, isLoading: true,
                        name: getIdentityChatName(twConversation, state.identity),                        
                        isInitiatedBySubscriber
                    }
                }
            };
        }            
            
        case chatConversationInitializedType: {
            const twConversation = action.twConversation;
            const messages = action.messages;
            const storageConversation = state.conversationByIds[twConversation.sid];
            const isInitiatedBySubscriber = storageConversation ? storageConversation.isInitiatedBySubscriber : false;
            return {
                ...state,
                conversationByIds: {
                    ...state.conversationByIds,
                    [twConversation.sid]: { 
                        ...storageConversation, isReady: true,
                        isLoading: false, messages: messages,
                        messagesLoaded: true,
                        isInitiatedBySubscriber
                    }
                }
            };
        }
        case messageAddedType: {
            const payload = action.payload;
            if (!payload) 
            {
                console.log(messageAddedType + " payload is null");
            }
            const twConversation = payload.twConversation;
            const m = payload.message;
            const storageConversation = state.conversationByIds[twConversation.sid];
            
            return {
                ...state,
                conversationByIds: {
                    ...state.conversationByIds,
                    [twConversation.sid]: { 
                        ...storageConversation, 
                        messages: [...storageConversation.messages, m],
                        isInitiatedBySubscriber: true
                    }
                },
                messageConsumedTimestamp: Date.now()
            };
        }
        case setMessageConsumedTimestampType: {
            const timestamp = action.timestamp;
            return {
                ...state,
                messageConsumedTimestamp: timestamp
            };
        }
        case setLayoutTimestampType: {
            const timestamp = action.timestamp;
            return {
                ...state,
                layoutTimestampType: timestamp
            };
        }
        
        case setConversationFilterType: {
            return {
                ...state,
                chatFilter: buildChatFilter(action.filterText),
                chatFilterText: action.filterText
            };
        }
        case setcurrentConversationIdType: {
            return {
                ...state,
                currentConversationId: action.id,
                renderRedirect: false
            };
        }

        case receiveProviderInNetworkType: {
            return {
                ...state,
                isProviderInNetwork: action.payload.isInSubscriberNetwork,
                providerCompanyId: action.payload.providerCompanyId,
                subscriberCompanyId: action.payload.subscriberCompanyId
            };
        }

        case resetProviderInNetworkType: {
            return {
                ...state,
                isProviderInNetwork: null,
                providerCompanyId: null,
                subscriberCompanyId: null
            };
        }

        case setProviderAndSubscriberIds: {
            return {
                ...state,
                subscriberId: action.payload.subscriberId,
                providerId: action.payload.providerId
            };
        }

        case openDeleteChatPopup:
            return {
                ...state,
                conversationForDelete: action.sid
            }

        case closeDeleteChatPopup:
            return {
                ...state,
                conversationForDelete: null,
                renderRedirect: action.renderRedirect
            }

        case confirmChatDelete:
            return {
                ...state,
                conversationForDelete: null
            }


        case setProviderBlockedStatus: {
            return {
                ...state,
                isAlreadyBlocked: action.blocked
            }
        }

        case showUnblockedMessage: {
            return {
                ...state,
                showUnblockedMessage: true
            }
        }  

        case hideUnblockedMessage: {
            return {
                ...state,
                showUnblockedMessage: false
            }
        }   


        case loadNewMessagesType: {
            const messages = action.messages;
            const storageConversation = state.conversationByIds[action.id];
            return {
                ...state,
                conversationByIds: {
                    ...state.conversationByIds,
                    [action.id]: {
                        ...storageConversation,
                        messages: messages
                    }
                }
            };
        }

        case setNetworkStatusesType:

            const statuses = action.statuses;
            const conversations = { ...state.conversationByIds };
            console.log(conversations)
            for (let k in conversations) {

                const attributes = conversations[k].conversation.channelState.attributes;

                const status = statuses.find(s => s.scProviderId == attributes.providerId && 
                    s.scSubscriberId == attributes.subscriberId);
                conversations[k].networkStatus = status ? status.status : null;
            }

            return {
                ...state,
                conversationByIds: conversations
            };
        case requestSidType:
        case initChatClientType:
        default:
            return state;
    }
};


const buildChatFilter = (filterText) => {
    if (!filterText)
        return (conversation) => true;

    const text = filterText.toLowerCase();
    return (conversation) => {
        if (filterText && conversation && conversation.name) {
            return conversation.name.toLowerCase().includes(text);
        }

        return true;
    };
};
