
import Client from '@twilio/conversations';


export const createChatClient = async (token, statusChangedCallback, conversationJoinedCallback,
    conversationLeftCallback, tokenExpiredCallback, conversationUpdatedCallback) => {
    try{
        console.info("Create client");
        const chatClient = await Client.create(token);
        chatClient.on('connectionStateChanged', (state) => {
            statusChangedCallback(state)
        });
        chatClient.on('conversationAdded', (twConversation) => {
            conversationJoinedCallback(twConversation)
        });
        chatClient.on('conversationUpdated', (twConversation) => {
            conversationUpdatedCallback(twConversation)
        });
        chatClient.on('conversationLeft', (twConversation) => {
            conversationLeftCallback(twConversation)
        });
        chatClient.on('tokenAboutToExpire', () => {
            tokenExpiredCallback()
        });
        return chatClient;
    }   
    catch (e) {
        console.info("Failed to Create client, token " + token, e);
        statusChangedCallback('error');
    }
};

export const setConversationCallbacks = (twConversation, messageAddedCallback)  => {
    try{
        if (!twConversation)
            return;
        
        twConversation.on('messageAdded', (m) => {
            messageAddedCallback({
                twConversation: twConversation, message: m
            })
        });
    }
    catch (e) {
        console.info("Failed to setConversationCallbacks" + twConversation.sid, e);
    }
};

export const loadConversationMessages = async (twConversation)  => {
    try{
        
        const messages = await twConversation.getMessages();
        
        return messages.items;
    }
    catch (e) {
        console.info("Failed to loadConversationMessages" + twConversation.sid, e);
        return null;
    }
};

export const setAllMessagesConsumed = async (twConversation)  => {
    try{
        if (hasUnreadMessages(twConversation)) 
        {
            await setAllMessagesConsumedInner(twConversation);
        }
    }
    catch (e) {
        console.info("Failed in setAllMessagesConsumed" + twConversation.sid, e);
    }
    
    return twConversation;
};

const setAllMessagesConsumedInner = async (twConversation)  => {
    try{
        console.info("setAllMessagesConsumedInner, conversation " + twConversation.sid);

        await twConversation.setAllMessagesRead();
    }
    catch (e) {
        console.info("Failed in setAllMessagesConsumedInner" + twConversation.sid, e);
    }

    return twConversation;
};

export const sendConversationMessage = async (twConversation, message, messageAttributes)  => {
    try{
        console.info("sendConversationMessage start, conversation " + twConversation.sid);

        await twConversation.sendMessage(message, messageAttributes);

        await setAllMessagesConsumedInner(twConversation);
        
        console.info("sendConversationMessage finished, conversation " + twConversation.sid);

        return true;
    }
    catch (e) {
        console.info("Failed to sendConversationMessage" + twConversation.sid, e);
        return false;
    }
};

export const getUserAttributes = (twConversation, identity) =>
{
    const state = twConversation.channelState;
    if (state)
    {
        const attributes = state.attributes;
        if (attributes && attributes.channelUsers && identity in attributes.channelUsers)
            return attributes.channelUsers[identity];
    }
    return null;
};

export const getIdentityChatName = (twConversation, identity) =>
{
    let name = twConversation.channelState.friendlyName
    const attributes = getUserAttributes(twConversation, identity);
    if (attributes)
        name = attributes;
    return name;
};

export const getLastMessageTimestamp = (twConversation) =>
{
    const state = twConversation.channelState;
    if (state && state.lastMessage)
        return state.lastMessage.dateCreated;
    return null;
};

export const hasUnreadMessages = (twConversation) =>
{
    const state = twConversation.channelState;
    if (!state)
        return false;
    
    const lastInd = state.lastReadMessageIndex;

    const lastMessage =  state.lastMessage;
    return ((lastMessage !== undefined && lastInd == null) ||
        (lastMessage && lastMessage.index !== lastInd));
};

export const updateChatClientSid = (twChatClient, token)  => {
    try{
        if (twChatClient)
        {
            console.info("updateChatClientSid ");

            twChatClient.updateToken(token);
        }
    }
    catch (e) {
        console.info("Failed in updateChatClientSid", e);
    }
};

export const getLasMessageId = (twConversation) =>
{
    const state = twConversation.channelState;
    if (!state)
        return false;

    const lastMessage =  twConversation.channelState.lastMessage;
    return (lastMessage) ? lastMessage.index : null;
};
