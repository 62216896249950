import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {actionCreators} from '../store/ChatStore';
import MessageBubble from "./MessageBubble";
import {getConversationById, getIdentity, 
        sendMessageIdentityPrefix, chatMessageIdentityPrefix} from "../store/selectors";
import SendMessage from "./SendMessage";
import BusyIndicator from "./BusyIndicator";
import ChatHeader from "./ChatHeader";

import "./assets/Chat.css"

class Chat extends Component {

    constructor(props) {
        super(props);
        this.state = { messagesCount: null };
    }

    componentDidMount() {
        this.props.initConversation(this.props.id);
        this.scrollToBottom();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id || !this.props.isReady)
            this.props.initConversation(this.props.id);
        this.scrollToBottom();
    }

    scrollToBottom() {

        if (this.props.messages) {
            const newCount = this.props.messages.length;
            const isScrollInView = this.state.messagesCount !== newCount;

            if (isScrollInView && this.messagesEnd) {
                this.messagesEnd.scrollIntoView();
                this.setState({ messagesCount: newCount });
            }
        }
    }

    render() {
        return this.props.isReady
            ? this.renderComponent(this.props, this.elementRef)
            : <BusyIndicator />
    }

    renderComponent(props, scrollRef) {


        return (
            <div className={"messages_container"}>
                <ChatHeader headerText={props.name} conversationSid={props.conversationSid} canClose={true}/>
                <div className={"scroll"} ref>
                    <div id="messages">
                        <ul>
                            {this.renderMessages(props, scrollRef)}
                        </ul>
                    </div>
                    <div style={{ float: "left", clear: "both" }}
                        ref={(el) => {
                            this.messagesEnd = el;
                        }}>
                    </div>
                </div>
                <SendMessage conversationSid={props.conversationSid} messagePrefix={props.sendPrefix} />
            </div>
        );
    }

    renderMessages(props, scrollRef) {
        if (!props.messages)
            return;
        return (
            props.messages.filter(entry => entry && entry.state.body)
                .map(entry => {
                    const m = entry.state;
                    const d = this.isOutgoing(props, m.author) ? 'outgoing' : 'incoming';
                    return (<MessageBubble
                        key={m.index} direction={d} message={m}
                        messagePrefix={props.chatMessagePrefix} type={'sms'} />);
                })
        );
    }

    isOutgoing(props, author)
    {
        if (author === props.identity) {
            return true;
        }
        return (props.identity.indexOf('pro') >= 0 && isPhone(author));
    }
}

function isPhone(author) {
    // const tmpl = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    const tmpl = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

    console.log("'" + author + "' " + author.match(tmpl));
    return author && author.match(tmpl);
};

function mapStateToProps(state, ownProps) {
    const conversationSid = ownProps.match.params.id;
    const conversation = getConversationById(state, conversationSid);
    const identity = getIdentity(state);
    const sendPrefix = sendMessageIdentityPrefix(state);
    const chatMessagePrefix = chatMessageIdentityPrefix(state);
    

    return { 
        ...conversation, identity: identity,
        sendPrefix: sendPrefix,
        chatMessagePrefix: chatMessagePrefix,
        conversationSid: conversationSid
    };
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Chat);
