import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router'
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/ChatStore';
import { Modal, Button } from 'react-bootstrap';
import { isSubscriberIdentity } from '../../store/selectors';
import '../assets/DeleteChatPopUp.css'

const DeleteChatPopUp = (props) => {

    if (props.renderRedirect) {
        const to = "/chat/";
        return (
            <Redirect to={to} />
        );
    }

    if (!props.conversationForDelete) {
        return null;
    }

    return (
        <div className="static-modal">
            <Modal.Dialog>
                <Modal.Header closeButton onHide={props.closeDeleteChatDialog}
                    className='delete-popup-header'>
                    Delete Conversation
                </Modal.Header>

                <Modal.Body className='delete-popup-body'>
                    {
                        props.isSubscriberIdentity ?
                            'Deleting this conversation will prevent this provider from continuing the chat. Are you sure you want to proceed?' :
                            'Deleting this conversation will prevent you from chatting further with this client. Are you sure you want to proceed?'
                    }                    
                </Modal.Body>

                <Modal.Footer className='delete-popup-footer'>
                    <Button bsClass={ 'link' } className={'cancel'} onClick={props.closeDeleteChatDialog}>Cancel</Button>
                    <Button className={'delete'} onClick={() => props.deleteConversation(props.conversationForDelete)}>
                        Delete chat
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        conversationForDelete: state.chatStore.conversationForDelete,
        isSubscriberIdentity: isSubscriberIdentity(state),
        renderRedirect: state.chatStore.renderRedirect
    };
}
export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(DeleteChatPopUp);