import React, {PureComponent} from "react";

export default class SendIcon extends PureComponent {
  render = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Vector" d="M15.466 0.227648L0.842156 8.66172C-0.367125 9.36133 -0.251118 11.1508 1.04253 11.6817L5.06058 13.3446V16.3118C5.06058 17.9431 7.14167 18.6145 8.10488 17.3173L9.6446 15.2395L13.5783 16.8638C13.7857 16.9481 14.0036 16.9903 14.2216 16.9903C14.5099 16.9903 14.7946 16.9165 15.0512 16.7723C15.5012 16.5192 15.8105 16.0692 15.8914 15.5594L17.9795 1.94681C18.1939 0.537026 16.6823 -0.471969 15.466 0.227648ZM6.74795 16.3118V14.0407L8.03457 14.5715L6.74795 16.3118ZM14.2216 15.3028L8.81498 13.0704L13.7435 5.95817C14.1196 5.41325 13.4095 4.78043 12.9104 5.21285L5.47539 11.6922L1.68584 10.1242L16.3097 1.68665L14.2216 15.3028Z" fill="white"/>
        </svg>
    );
  }
}