import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Button} from "antd";
import FilterIcon from "./icons/FilterIcon"
import {bindActionCreators} from "redux";
import {actionCreators} from "../store/ChatStore";
import './assets/ChatFilter.css';
import {getChatFilterText, isProviderIdentity} from "../store/selectors";

class ChatFilter extends Component {

    constructor(props) {
        super(props);
        this.onFilter = this.onFilter.bind(this);
        this.onFilterTextChanged = this.onFilterTextChanged.bind(this);
    }
    
    onFilter = event => {
        this.props.setConversationFilter(this.props.chatFilterText);
    };
    
    onFilterTextChanged = event => {
        this.props.setConversationFilter(event.target.value);
    };
    
    render() {
        const partnerType = this.props.isProvider ? "subscriber": "provider";
        const placeholderText = `Search for ${partnerType} name...`;
        const filterText = this.props.chatFilterText;
        return (
            <div className={"chat-filter"}>
                <div>
                    <input type={"text"} 
                           placeholder={placeholderText} 
                           onChange={this.onFilterTextChanged}
                           value={filterText}
                           autoComplete={"off"}
                    >
                    </input >
                </div>

                <Button  onClick={this.onFilter} >
                    <FilterIcon />
                </Button>
            </div>
        );
    }

}

function mapStateToProps(state, ownProps) {
    const chatFilterText = getChatFilterText(state);
    const isProvider = isProviderIdentity(state);
    return {
        chatFilterText: chatFilterText,
        isProvider: isProvider
    };
}
export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ChatFilter);