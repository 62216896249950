
export const requestSid = async ()  => {
    let result = {
        ErrorCode: 500
    };
    try {

        const url = `/api/users/current/newSid`;
        const response = await fetch(url, {method: 'POST'}).catch();

        if (response) {
            if (response.status === 401) {
                result.ErrorCode = 401
            }
            else if (response.status >= 500)
            {
                result.ErrorCode = 500
            }
            else {
                return await response.json();
            }
        }
    }
    catch (ex)
    {
        console.error(ex);
    }
    return result;

};

export const prepareConversation = async (type, id)  => {

    try{
        const url = `api/${type}/${id}/conversation/prepare`;
        const response = await fetch(url, {method: 'POST'});
        const conversationInfo = await response.json();
        console.log(conversationInfo);
        return conversationInfo;
    }
    catch (ex)
    {
        console.error(ex);
    }
};

export const requestUserStatus = async ()  => {
    let result = {
        ErrorCode: 500
    };
    try {

        const url = `/api/users/current/status`;
        const response = await fetch(url).catch();

        if (response) {
            if (response.status === 401) {
                result.ErrorCode = 401
            }
            else if (response.status >= 500)
            {
                result.ErrorCode = 500
            }
            else {
                var status = await response.json();
                console.log(status);
                return status;
            }
        }
    }
    catch (ex)
    {
        console.error(ex);
    }
    return result;
};

export const sendExpireStatus = async ()  => {
    let result = {
        ErrorCode: 500
    };
    try {

        const url = `/api/users/current/expirestatus`;
        const response = await fetch(url, {method: 'POST'}).catch();

        if (response) {
            if (response.status === 401) {
                result.ErrorCode = 401
            }
            else if (response.status >= 500)
            {
                result.ErrorCode = 500
            }
            else {
                result.ErrorCode = response.status;
                return result;
            }
        }
    }
    catch (ex)
    {
        console.error(ex);
    }
    return result;
};


export const getSubscriberInviteData = async (subscriberId, providerIds) => {

    let result = {
        ErrorCode: 500
    };

    try {
        const url = `/api/subscribers/${subscriberId}/network_statuses`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                scSubscriberId: subscriberId,
                scProviderIds: providerIds
            })
        }).catch();

        if (response) {
            if (response.status === 401) {
                result.ErrorCode = 401
            }
            else if (response.status >= 500) {
                result.ErrorCode = 500
            }
            else {
                return await response.json();
            }
        }
    }
    catch (ex) {
        console.error(ex);
    }

    return result;
}

export const getProviderInviteData = async (providerId, subscriberIds) => {

    let result = {
        ErrorCode: 500
    };

    try {
        const url = `/api/providers/${providerId}/network_statuses`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                scProviderId: providerId,
                scSubscriberIds: subscriberIds
            })
        }).catch();

        if (response) {
            if (response.status === 401) {
                result.ErrorCode = 401
            }
            else if (response.status >= 500) {
                result.ErrorCode = 500
            }
            else {
                return await response.json();
            }
        }
    }
    catch (ex) {
        console.error(ex);
    }

    return result;
}

export const updateConversationAttributes = async (sid, attributes) => {

    let result = {
        ErrorCode: 500
    };

    try {
        const url = `api/conversation`;
        const response = await fetch(url,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    attributes,
                    pathSid: sid
                })
            }).catch();

        if (response) {
            if (response.status === 401) {
                throw "updateConversationAttributes: error 401";
            }
            else if (response.status >= 500) {
                throw "updateConversationAttributes: error 500";
            }
        }
    }
    catch (ex) {
        console.error(ex);
    }

    return result;
}


export const requestStartTrackingSubscriber = async (providerId, subscriberId) => {

    try {
        const url = "/api/providers/StartTrackingSubscriber";
        const response = await fetch(url, 
            {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    providerId,
                    subscriberId
                })
            }).catch();

        if (response) {
            if (response.status === 401) {
                throw "startTrackingSubscriber: error 401";
            }
            else if (response.status >= 500) {
                throw "startTrackingSubscriber: error 500";
            }
        }
    }
    catch (ex) {
        console.error(ex);
    }
}


export const requestStopTrackingSubscriber = async (providerId, subscriberId) => {

    try {
        const url = "/api/providers/StopTrackingSubscriber";
        const response = await fetch(url, 
            {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    providerId,
                    subscriberId
                })
            }).catch();

        if (response) {
            if (response.status === 401) {
                throw "stopTrackingSubscriber: error 401";
            }
            else if (response.status >= 500) {
                throw "stopTrackingSubscriber: error 500";
            }
        }
    }
    catch (ex) {
        console.error(ex);
    }
}

export const requestProviderBlocked = async (providerId) => {

    try {
        const url = "/api/providers/GetBlockedProviders";
        const response = await fetch(url, 
            {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ProviderIds: [providerId]
                })
            }).catch();

        if (response) {
            if (response.status === 401) {
                throw "requestProviderBlocked: error 401";
            }
            else if (response.status >= 500) {
                throw "requestProviderBlocked: error 500";
            }
            else {
                return await response.json();
            }
        }
    }
    catch (ex) {
        console.error(ex);
    }
}

export const requestUnblockProvider = async (providerId) => {

    try {
        const url = "/api/providers/UnblockProvider";
        const response = await fetch(url, 
            {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: providerId
            }).catch();

        if (response) {
            if (response.status === 401) {
                throw "unblockProvider: error 401";
            }
            else if (response.status >= 500) {
                throw "unblockProvider: error 500";
            }
        }
    }
    catch (ex) {
        console.error(ex);
    }
}