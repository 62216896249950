import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect} from "react-router-dom";

import "./assets/Chat.css"
import {noMessagesFullConst} from "../impl/ChatConsts"
import {
    getConversationsList,
    hasAnyStartedConversation,
    isSubscriberIdentity
} from "../store/selectors";


class EmptyMessage extends Component {
    
    render() {
        return this.renderComponent(this.props);
    }

    renderComponent() {
        if (this.props.hasConversations) {
            return this.renderRedirectToChatRoot();
        }
        return (
            <div className={'nomessage'}>
                <h3>{noMessagesFullConst}</h3>
            </div>
        );
    }

    renderRedirectToChatRoot() {
        const to = "/chat/";
        return (
            <Redirect to={to} />
        );
    }
}
function mapStateToProps(state, ownProps) {
    
    const ids = getConversationsList(state);
    
    return {
        hasConversations: ids && ids.length && 
            (isSubscriberIdentity(state) || hasAnyStartedConversation(state))
    };
}
export default connect(
    mapStateToProps
)(EmptyMessage);
