import React from 'react';
import {connect} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './assets/BusyIndicator.css';

const BusyIndicator = props => (
    
    <div className={"busy-indicator"}>
        <div className={"loader"}>
            <FontAwesomeIcon icon="spinner" spin />
        </div>
    </div>
);

export default connect()(BusyIndicator);