import React from 'react';
import {connect} from 'react-redux';
import {actionCreators} from '../store/ChatStore';
import {bindActionCreators} from "redux";
import {Col, Grid, Row} from 'react-bootstrap';
import NavMenu from './NavMenu';
import BlockedMessage from './BlockedMessage';
import UnblockedMessage from './UnblockedMessage';
import './assets/Layout.css';

const Layout = props => {
    return props.hasNavbar ?
        renderLayoutWithNavbat(props) :
        renderLayoutWithoutNavbat(props);
};

const renderLayoutWithoutNavbat = function(props) {
    return (
        <Grid fluid className={".no-navbar"}>
            <Row>
                <Col sm={12}>
                    {props.children}
                </Col>
            </Row>
        </Grid>
    );
};

const renderLayoutWithNavbat = function(props) {
    return (   
        <React.Fragment>
        { renderProviderBlockedHeader(props) }
        { renderProviderUnblockedPopUp(props) }
            <Grid fluid>
                <Row>
                    <Col className={"col-fixed-495"} xsHidden={true}>
                        <NavMenu/>
                    </Col>
                    <Col className={"col-offset-495"}>
                        <Row>
                            <Col sm={12}>
                                {props.children}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Grid>
        </React.Fragment>     
    );
};

const renderProviderBlockedHeader = function(props) {
    
    if(!props.isAlreadyBlocked) {
        return null;
    }
    
    return (
        <div className={'blocked-panel'}>
            <BlockedMessage/>
        </div>
    );
    
}

const renderProviderUnblockedPopUp = function(props) {
    return (
        <UnblockedMessage {...props}/>
    );    
}

const mapStateToProps = (state) => {
    return {
        isAlreadyBlocked: state.chatStore.isAlreadyBlocked,
        showUnblockedMessage: state.chatStore.showUnblockedMessage
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        hideUnblockedProviderMessage: actionCreators.hideUnblockedProviderMessage
    },
    dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Layout)
