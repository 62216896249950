
import { prepareProviderConversationType } from "./ActionTypes";
import {prepareConversation} from "../impl/ChatMessagesApiCalls";

const initialState = {
    sid: '',
    isReady: false
};

export const actionCreators = {

    PrepareProviderConversation: id => async (dispatch, getState) => {

        const payload = await prepareConversation("providers", id);

        dispatch({type: prepareProviderConversationType, payload});
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === prepareProviderConversationType) {
        const payload = action.payload;
        if (payload)
        {
            const sid = payload.sid;
            return {
                ...state,
                isReady: true,
                sid: sid
            };
        }
    }

    return state;
};