import React, {Component} from 'react';
import {matchPath, Route, Switch} from 'react-router';
import {connect} from 'react-redux';
import {actionCreators} from './store/ChatStore';
import {redirectToLogin} from './impl/ChatStoreActions';
import Layout from './components/Layout';
import Chat from './components/Chat';
import PrepareProviderConversation from './components/PrepareProviderConversation';
import {bindActionCreators} from "redux";
import BusyIndicator from "./components/BusyIndicator";
import InitialMessage from "./components/InitialMessage";
import {noConversationPathnameConst} from "./impl/ChatConsts";
import EmptyMessage from "./components/EmptyMessage";

import "./components/assets/Chat.css"


export class App extends Component {

    constructor(props) {
        super(props);
        this.state = { hasNavbar: true };
        this.messageTemplate = '/chat/:id/message';
        this.providerTemplate = '/providers/:id';
    }

    componentDidMount() {
        if (this.props.unauthorized)
        {
            redirectToLogin();
            return false;
        }
        this.props.initChatClient();
        this.updateNavbarState();
    }
    
    componentDidUpdate(prevProps) {
        this.updateNavbarState();
    }
    
    render() {
        if (this.props.unauthorized) {
            redirectToLogin();
            return false;
        }
        
        if (!this.props.isReady)
            this.renderBusyState();

        
        return this.renderRoutes();
    }

    renderBusyState(){
        return (
            <div className={"messages_container im-container"}>
                <BusyIndicator />
            </div>
        );
    }
    
    renderRoutes() {
        return ( 
            <Switch>
                <Route path={this.messageTemplate} component={InitialMessage} exact={true}/>
                <Route exact path={this.providerTemplate} component={PrepareProviderConversation}/>
                <Route path={noConversationPathnameConst} component={EmptyMessage} exact={true}/>
                {this.renderConversations()}
            </Switch>
        );    
    }

    renderConversations() {
        return (
            <Layout hasNavbar={this.state.hasNavbar}>
                <Route path='/chat/:id' component={Chat} exact={true}/>
            </Layout>
        );
    }
  
    isMessagePage(pathname) {
        return this.isMatchTemplate(this.messageTemplate, pathname);  
    }
    
    isProviderPage(pathname) {
        return this.isMatchTemplate(this.providerTemplate, pathname);
    }
    
    isMatchTemplate(template, pathname) {
        const match = matchPath(pathname, {
            path: template
        });
        return Boolean(match && match.isExact);
    }
    isChatPage(pathname) {
        return this.isMatchTemplate('/chat/:id', pathname) ||
            this.isMatchTemplate(['/'], pathname) ||
            this.isMatchTemplate(['/chat'], pathname);
        
    }
    updateNavbarState() {
        const pathname = window.location.pathname;
        const hasNavbar = this.isChatPage(pathname) &&
            !this.isMessagePage(pathname) && 
            !this.isProviderPage(pathname) &&
            !this.isMatchTemplate(noConversationPathnameConst, pathname);

        if (this.state.hasNavbar !== hasNavbar) {
            this.setState({hasNavbar: hasNavbar});
        }
    }
}

export default connect(
    state => state.chatStore,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(App)

