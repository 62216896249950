import React, {Component} from 'react';
// import {Link} from 'react-router-dom';
import {Navbar, NavItem} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import './assets/NavMenu.css';
import {connect} from "react-redux";
import {
    getConversationById,
    getConversationTimestamp,
    getCurrentConversationId,
    getFilteredConversationIds,
    getMessageConsumedTimestamp,
    hasConversationUnreadMessages,
    getConversationsList,
    isProviderIdentity,
    chatMessageIdentityPrefix
} from "../store/selectors"
import ChatFilter from './ChatFilter'
import RecentMessages from './RecentMessages'
import EllipseIcon from './icons/EllipseIcon';
import {bindActionCreators} from "redux";
import {actionCreators} from "../store/ChatStore";
import {Redirect} from "react-router-dom";
import {noConversationPathnameConst} from "../impl/ChatConsts";


export class NavMenu extends Component {
    constructor(props) {
        super(props);
        this.state = { isEmptyMessageTimeout: false };
    }
    componentDidMount() {
        this.setState({ isMounted: true });
        this.scheduleRedirectToEmptyMessate.bind(this);
        this.checkCurrentConversation();
        this.scheduleRedirectToEmptyMessate();
    }

    componentDidUpdate(prevProps) {
        this.checkCurrentConversation();
    }

    componentWillUnmount() {
        if (this.timeout)
        {
            clearTimeout(this.timeout);  
        } 
    }
    
    checkCurrentConversation() {
        const id = this.props.currentConversationId;
        const pathname = window.location.pathname;
        if (pathname.indexOf(id) < 0) {
            this.props.updateCurrentConversationId(pathname);
        }
    }
    render() {
        return (<Navbar inverse fixedTop fluid collapseOnSelect>
                <Navbar.Header>
                    <ChatFilter />
                </Navbar.Header>
                <Navbar.Collapse>
                    <div className={"scroll"}>
                    <ul>
                        {this.renderConversations()}
                    </ul>
                    </div>
                </Navbar.Collapse>
            </Navbar>
        );
    }

    scheduleRedirectToEmptyMessate(){
        this.timeout = setTimeout(function(){
            this.setState({isEmptyMessageTimeout: true});
        }.bind(this), 1000);
    }

    renderRedirectToEmptyMessate() {
        if (this.state.isEmptyMessageTimeout) {
            return (
                <Redirect to={noConversationPathnameConst} />
            );
        }
    }

    renderRedirectToChatRoot() {
        const to = "/chat/";
        return (
            <Redirect to={to} />
        );
    }

    renderConversations()
    {
        if (this.props.hasConversations)
        {
            if (window.location.pathname === noConversationPathnameConst)
            {  
                return this.renderRedirectToChatRoot();
            }
            return this.props.conversationIds.map((id) => {
                return this.renderConversationLink(id)
            });
        }
        if (window.location.pathname !== noConversationPathnameConst && 
            this.state.isEmptyMessageTimeout) 
        {
            return this.renderRedirectToEmptyMessate();
        }
    }

    renderConversationLink(id) {

        const path = '/chat/';
        const conversation = this.props.getConversationById(id);

        if (conversation.conversation.channelState.attributes.isClosed) {
            return null;
        }
        
        if(!conversation.isInitiatedBySubscriber && this.props.isProvider) {
            return null;
        }

        const data = getConversationData(conversation);
        var isCurrent = id === this.props.currentConversationId;
        
        const boldClassName = data.hasUreadMessages ? ' bold' : '';
        const currentClass = isCurrent ? ' current' : '';
        
        const hasMessages = data?.messages.length > 0;
        const titleRowStyle = hasMessages ? "nav-row-50" : "nav-row-100";
        const messagesRowStyle = hasMessages ? "nav-row-50" : "";
        
        return (
            <LinkContainer to={path + id} key={id} className={currentClass}>
                <NavItem>
                    <table><tbody>
                        <tr className={titleRowStyle}>
                            <td className={'conversation-name' + boldClassName}>
                                <span>{data.name}</span>
                            </td>
                            <td className={'network-status'}>
                                {this.renderNetworkStatusBlock(data.networkStatus)}
                            </td>
                            <td className={'conversation-status'} rowSpan='2'>
                                {this.renderStatusIcon(data.hasUreadMessages)}
                            </td>
                        </tr>
                        <tr className={messagesRowStyle}>
                            <td colSpan = '2'>
                                <RecentMessages conversationData={ data }/>
                            </td>
                        </tr>
                    </tbody></table>
                </NavItem>
            </LinkContainer>
        );
    }
    
    renderStatusIcon(hasUreadMessages){
        let icon;
        if (hasUreadMessages)
            icon = <EllipseIcon />;
        
        return icon;
    }

    renderNetworkStatusBlock(status) {
        let statusText = null;
        switch (status) {
            case 'InNetwork':
                statusText = 'In Network';
                break;
            case 'Pending':
                statusText = 'Pending';
                break;
            case 'NewInvite':
                statusText = 'New Invite';
        }

        if (statusText == null) {
            return null;
        }

        return (
            <span>{statusText}</span>
        );
    }
}


const getConversationData = (conversation) => {
    const hasUnread = hasConversationUnreadMessages(conversation);
    const ts = getConversationTimestamp(conversation);
    let dtString;
    if (ts) {
        var today = new Date().setHours(0, 0, 0, 0);
        var thatDay = new Date(ts).setHours(0, 0, 0, 0);

        if (today === thatDay) {
            dtString = ts.toLocaleTimeString(undefined, {
                hour: '2-digit',
                minute: '2-digit'
            });        
        }
        else{
            dtString = ts.toLocaleDateString(undefined, {
                month: 'short', day: '2-digit', year: 'numeric'
            });
        }
    }

    return {
        name: conversation.name,
        dtString: dtString,
        hasUreadMessages: hasUnread,
        messages: conversation.messages,
        lastReadIndex: conversation.conversation?.channelState?.lastReadMessageIndex,
        networkStatus: conversation.networkStatus
    };
};

function mapStateToProps(state, ownProps) {
    const ids = getConversationsList(state);
    const chatMessagePrefix = chatMessageIdentityPrefix(state);
    return { 
        hasConversations: ids && ids.length,
        conversationIds: getFilteredConversationIds(state),
        getConversationById: (id) => getConversationById(state, id),
        currentConversationId: getCurrentConversationId(state),
        messageConsumedTimestamp: getMessageConsumedTimestamp(state),
        isProvider: isProviderIdentity(state),
        chatMessagePrefix: chatMessagePrefix
    };
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(NavMenu)
