import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './assets/UnblockedMessage.css'

const UnblockedMessage = (props) => {

    if (!props.showUnblockedMessage) {
        return null;
    }

    return (
        <div className="static-modal">
            <Modal.Dialog>
                <Modal.Header closeButton onHide={props.hideUnblockedProviderMessage}
                    className='unblocked-popup-header'>
                </Modal.Header>

                <Modal.Body className='unblocked-popup-body'>
                    You can now receive messages.
                </Modal.Body>

                <Modal.Footer className='unblocked-popup-footer'>
                    <Button onClick={props.hideUnblockedProviderMessage}>OK, close</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </div>
    );
}

export default UnblockedMessage;
