import React, {Component} from 'react';
import { connect } from 'react-redux';
// import Icon, { MobileOutlined } from '@ant-design/icons';
// import WhatsappIcon from "./WhatsappIcon";
// import ChatIcon from "./ChatIcon";
import './assets/MessageBubble.css';

class MessageBubble extends Component {

    render() {

        let itemStyle = "outgoing_msg";
        let divStyle = "sent_msg";

        if (this.props.direction === "incoming") {
            itemStyle = "received_msg";
            divStyle = "received_width_msg";
        }
        
        if (!this.props || !this.props.message)
        {
            console.log("MessageBubble.render message is null");
        }
        const m = this.props.message;
        let body = m.body;
        if (body && this.props.messagePrefix)
        {
            body = body.replace(this.props.messagePrefix, "");
        }
        const dtString = buildDtString(m.timestamp);
        return (<li id={m.sid} className={itemStyle}>
            <div className={divStyle}>
                {renderMessageTime(dtString)}
                <div className={"msg_body"}>
                    {body}
                </div>
            </div>
        </li>);
    }
}
const renderMessageTime = (dtString) => {
    if (dtString) {
        return (
            <div className="time_date_container">
                        <span className={"time_date"}>{dtString}</span>
            </div>
        );
    }
};

const buildDtString = (ts) => {
    let dtString = '';
    if (ts) {
        var today = new Date().setHours(0, 0, 0, 0);
        var thatDay = new Date(ts).setHours(0, 0, 0, 0);
    
        dtString = ts.toLocaleTimeString(undefined, {
            hour: '2-digit',
            minute: '2-digit'
        });
        if (today !== thatDay) {
            dtString = ts.toLocaleDateString(undefined, {
                month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'
            });
        }
    }
    return dtString;
};


export default connect()(MessageBubble);