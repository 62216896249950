import React from 'react';
import {connect} from 'react-redux';
import {actionCreators} from '../store/ChatStore';
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import './assets/BlockedMessage.css'

const BlockedMessage = (props) => {
    console.log(props);
    return (
        <div className={'blocked-label'}>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <span>
                Due to inactivity on your account, new subscribers can no longer message you from Provider Search.
                Click <button onClick={props.unblockProvider} type="button" className="btn btn-link">here</button>&nbsp;
                to be able to receive messages again.
            </span>
        </div>
    );
}

const mapStateToProps = (state) => {return {};}

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        unblockProvider: actionCreators.unblockProvider
    },
    dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BlockedMessage);
