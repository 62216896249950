import {
    addConversationType, updateConversationType, chatConversationInitializedType, chatClientInitializedType,
    chatClientStateChangedType, initChatConversationType,
    messageAddedType, receiveSidType,
    removeConversationType, requestSidType,
    setConversationFilterType, setcurrentConversationIdType, 
    setMessageConsumedTimestampType, setLayoutTimestampType,
    setUnauthorizedType,
    receiveProviderInNetworkType,
    resetProviderInNetworkType,
    setProviderAndSubscriberIds,
    openDeleteChatPopup,
    closeDeleteChatPopup,
    confirmChatDelete,
    setProviderBlockedStatus,
    showUnblockedMessage,
    hideUnblockedMessage,
    loadNewMessagesType,
    setNetworkStatusesType
} from "../store/ActionTypes";
import {
    getConversationById, getConversationsList, getChatClient, getCurrentConversationId,
    getCurrentSubscriberId, getCurrentProviderId, isSubscriberIdentity, isProviderIdentity, isCurrentProviderAlreadyBlocked
} from "../store/selectors";
import {
    createChatClient,
    loadConversationMessages,
    sendConversationMessage,
    setAllMessagesConsumed,
    updateChatClientSid,
    setConversationCallbacks
} from "./ChatClientProxy";
import {
    getSubscriberInviteData, getProviderInviteData, requestSid, sendExpireStatus, updateConversationAttributes,
    requestStartTrackingSubscriber, requestStopTrackingSubscriber, requestProviderBlocked, requestUnblockProvider
} from "./ChatMessagesApiCalls";

///////////////////////////////////////////////////////////////////////////////
// payload section
export const addConversationPayload = (twConversation) => ({type: addConversationType, twConversation: twConversation});
export const removeConversationPayload = (twConversation) => ({type: removeConversationType, id: twConversation.sid});
export const setConversationFilterPayload = (filterText) => ({type: setConversationFilterType, filterText: filterText});
const connectionStateChangedPayload = (status) => ({ type: chatClientStateChangedType, status });

const buildDispatcher = (dispatch,  payloadBuilder) => {
    return (arg) => { 
        dispatch(payloadBuilder(arg)) 
    };
};

///////////////////////////////////////////////////////////////////////////////
// Export functions section

export const initConversationFunction = (id) => async (dispatch, getState) => {
    await initConversation(dispatch, getState, id);
};

export const updateCurrentConversationIdFunction = (currentUrl) => async (dispatch, getState) => {
    await updateCurrentConversationId(dispatch, getState(), currentUrl);
};

export const sendMessageFunction = (id, body) => async (dispatch, getState) => {
    await sendMessage(dispatch, getState(), id, body);
};

export const updateLayoutTimestampFunction = () => async (dispatch, getState) => {
    await updateLayoutTimestamp(dispatch, getState());
};

export const openDeleteChatPopupFunction = (sid) => ({ type: openDeleteChatPopup, sid });

export const closeDeleteChatPopupFunction = () => ({ type: closeDeleteChatPopup });

export const deleteConversationFunction = (sid) => async (dispatch, getState) => {
    await requestConversationDelete(sid, dispatch, getState());
};

export const unblockProvider = () => async (dispatch, getState) => {
    await unblockCurrentProvider(dispatch, getState());
};

export const hideUnblockProviderMessageFunction = () => async (dispatch, getState) => {
    await hideUnblockProviderMessage(dispatch, getState());
};
///////////////////////////////////////////////////////////////////////////////
// Implementation section

const buildTokenExpiredCallback = (dispatch, getState) => {
    return async () => {
        await initChatClient(dispatch, getState);
    }
};

const buildMessageAddedCallback = (dispatch, getState) => {
    return async (payload) => {
        await messageAdded(dispatch, getState, payload);
    }
};

const conversationAddedCallback = (dispatch, getState) => {
    return (payload) => {
        conversationAdded(dispatch, getState, payload);
    }
};

const conversationUpdatedCallback = (dispatch, getState) => {
    return (payload) => {
        conversationUpdated(dispatch, getState, payload);
    }
};

export const initChatClient = async (dispatch, getState) => {

    const payload = await requestUserSid(dispatch, getState());

    let chatClient = getChatClient(getState());

    if (!chatClient) {
        chatClient = await createChatClient(
            payload.token,
            buildDispatcher(dispatch, connectionStateChangedPayload),
            conversationAddedCallback(dispatch, getState),
            buildDispatcher(dispatch, removeConversationPayload),
            buildTokenExpiredCallback(dispatch, getState),
            conversationUpdatedCallback(dispatch, getState)
        );
    }
    else {
        updateChatClientSid(chatClient, payload.token);
    }

    dispatch({type: chatClientInitializedType, chatClient});
    await checkIfProviderIsBlocked(dispatch, getState);
    await requestNetworkStatuses(chatClient, dispatch, getState());
};

const setcurrentConversationId = async (dispatch, state, id) => {
    const currentId = getCurrentConversationId(state);
    if (currentId !== id) {
        dispatch({type: setcurrentConversationIdType, id: id});
        const conversation = getConversationById(state, id);
        if (conversation && conversation.conversation) {
            await setAllMessagesConsumedWithTimeout(dispatch, conversation.conversation);
            await sendExpireUserStatus();
            setCurrentConversationData(state, conversation, dispatch);
        }
    }
};

const updateCurrentConversationId = async (dispatch, state, currentUrl) => {
    const ids = getConversationsList(state);
    if (!ids)
        return;
    let id = ids.find(id => currentUrl.indexOf(id) >= 0);
    if (!id) {
        id = null;
    }
    await setcurrentConversationId(dispatch, state, id);
};


const sendExpireUserStatus = async (dispatch) => {

    const payload = await sendExpireStatus();

    if (payload.ErrorCode === 401) {
        dispatch({type: setUnauthorizedType, payload});
    }
};

const requestUserSid = async (dispatch, state) => {
    dispatch({type: requestSidType});

    const payload = await requestSid();

    if (payload.ErrorCode === 401) {
        dispatch({type: setUnauthorizedType, payload});
    }
    dispatch({type: receiveSidType, payload});

    return payload;
};

const initConversation = async (dispatch, getState, id) => {
    const conversation = getConversationById(getState(), id);
    if (conversation.isReady) {
        return;
    }
    if (conversation.isLoading) {
        return;
    }

    if (conversation && conversation.conversation && !conversation.messagesLoaded)
    {
        let twConversation = conversation.conversation;

        dispatch({type: initChatConversationType, twConversation});

        const messages = await loadConversationMessages(conversation.conversation);

        dispatch({type: chatConversationInitializedType, twConversation, messages });

        await setAllMessagesConsumedWithTimeout(dispatch, conversation.conversation);

        await sendExpireUserStatus();

        setCurrentConversationData(getState(), conversation, dispatch);
    }
};

const loadNewMessages = async (dispatch, getState, id) => {
    const conversation = getConversationById(getState(), id);
    if (conversation.isReady) {
        return;
    }
    if (conversation.isLoading) {
        return;
    }

    if (conversation && conversation.conversation && !conversation.messagesLoaded) {

        const messages = await loadConversationMessages(conversation.conversation);

        dispatch({ type: loadNewMessagesType, id, messages });
    }
}

const setAllMessagesConsumedWithTimeout = async (dispatch, twConversation) => {

    await setAllMessagesConsumed(twConversation);

    setTimeout(async () =>  {
        await setAllMessagesConsumed(twConversation);

        await updateMessageConsumedTimestamp(dispatch)
    }, 1500);
};

const sendMessage = async (dispatch, state, id, body) => {
    const conversation = getConversationById(state, id);
    if (!conversation || !conversation.isReady) {
        console.log("sendMessage - conversation is not ready " + id);
        return;
    }
    if (conversation.isLoading) {
        console.log("sendMessage - conversation iniitialization is in progress" + id);
        return;
    }
    
    await sendConversationMessage(conversation.conversation, body,
                             { sentBy: state.chatStore.userId });
    // console.log("sendMessage - " + id + " res=" + res);
};

const conversationAdded = async (dispatch, getState, twConversation) => {

    var isInitiatedBySubscriber = await twConversation.getMessagesCount() > 0;

    dispatch({type: addConversationType, twConversation: twConversation, isInitiatedBySubscriber});

    setConversationCallbacks(
        twConversation, buildMessageAddedCallback(dispatch, getState)
    );

    await loadNewMessages(dispatch, getState, twConversation.sid);
};

const conversationUpdated = (dispatch, getState, twConversation) => {

    dispatch({ type: updateConversationType, twConversation: twConversation });

};
const messageAdded = async (dispatch, getState, payload) => {

    dispatch({type: messageAddedType, payload: payload});

    let twConversation = payload.twConversation;
    if (!twConversation)
        return;

    const conversationId = twConversation.sid;
    const currentConversationId = getCurrentConversationId(getState());
    let attributes = twConversation.channelState.attributes;

    if (currentConversationId && conversationId && (currentConversationId === conversationId)) {
        await setAllMessagesConsumedWithTimeout(dispatch, twConversation);
    }
    else{
        await updateMessageConsumedTimestampWithTimeout(dispatch);
    }
    if (!attributes.isInitiatedBySubscriber && isSubscriberIdentity(getState())) {
        attributes.isInitiatedBySubscriber = true;
        await Promise.all([
            twConversation.updateAttributes(attributes),
            requestStartTrackingSubscriber(attributes.providerId, attributes.subscriberId)
        ]);
    }
    else if (!attributes.isProviderAnswered && payload.message.state.author.match(/pro/)) {
        attributes.isProviderAnswered = true;
        await Promise.all([
            twConversation.updateAttributes(attributes),
            requestStopTrackingSubscriber(attributes.providerId, attributes.subscriberId)
        ]);
    }
};

export const updateMessageConsumedTimestamp = async (dispatch) => {
    dispatch({type: setMessageConsumedTimestampType, timestamp: Date.now()});
};

const updateMessageConsumedTimestampWithTimeout = async (dispatch) => {
    setTimeout(async () =>  {
        await updateMessageConsumedTimestamp(dispatch);

    }, 1000);
};

const updateLayoutTimestamp = async (dispatch) => {
    dispatch({type: setLayoutTimestampType, timestamp: Date.now()});
};

export const redirectToLogin = () => {
    const pathname = window.location.pathname;
    const nc = Math.random().toString(36).substring(2, 6) +
        Math.random().toString(36).substring(2, 6);
    window.location.href = `/account/login?returnUrl=${encodeURI(pathname)}&nc=${encodeURI(nc)}`;
};

export const redirectToMc = (conversation) => {
    let  host = window.location.host;

    const pathname = `/MCenter/Index?conversation=${encodeURI(conversation)}`;
    let href = pathname;
    
    if (host.indexOf("localhost") < 0) {//not localhost
        if (host.indexOf(".serviceconversation.") >= 0) {// redirect to fixxbook
            host = host.replace("chat", "fixxbook");
        }
        href = `https://${host}${pathname}`;
        window.top.location.href = href;
    }
    else {
        href = `http://localhost/fixxbookPlus${pathname}`;
        window.top.location.href = href;
    }
};

/**
 * Read provider identifier from Twilio conversation.
 * Provider identifier is parsed from uniqueName of the conversation 
 * (like "dev1_sub0001_pro0002", where 0002 is provider identifier).
 * 
 * @param {any} conversation Twilio conversation.
 */
const getProviderId = (conversation) => {
    const providerIdRegExp = /(?:pro)([\d]+)/i;
    if (!conversation || !conversation.uniqueName)
        return 0;
    const match = conversation.uniqueName.match(providerIdRegExp);
    if (match)
        return parseInt(match[1]);
    return 0;
}

/**
 * Read subscriber identifier from Twilio conversation.
 * Subscriber identifier is parsed from uniqueName of the conversation 
 * (like "dev1_sub0001_pro0002", where 0001 is subscriber identifier).
 * 
 * @param {any} conversation Twilio conversation.
 */
const getSubscriberId = (conversation) => {
    const subscriberIdRegExp = /(?:sub)([\d]+)/i;
    if (!conversation || !conversation.uniqueName)
        return 0;
    const match = conversation.uniqueName.match(subscriberIdRegExp);
    if (match)
        return parseInt(match[1]);
    return 0;
}

function setCurrentConversationData(state, conversation, dispatch) {
    const subscriberId = getSubscriberId(conversation.conversation);
    const providerId = getProviderId(conversation.conversation);

    dispatch({
        type: setProviderAndSubscriberIds,
        payload: {
            subscriberId: subscriberId,
            providerId: providerId
        }
    });
}

const checkIfProviderIsBlocked = async (dispatch, getState) => {
    if(!isProviderIdentity(getState()) || isCurrentProviderAlreadyBlocked(getState())) {
        return;
    }

    const providerId = getCurrentProviderId(getState());
    const payload = await requestProviderBlocked(providerId);

    if (payload && payload.providerIdsWithBlockStatus[providerId.toString()]){
        dispatch({type: setProviderBlockedStatus, blocked: true});
    }
}


const requestConversationDelete = async (sid, dispatch, state) => {
    dispatch({ type: closeDeleteChatPopup });
    let attributes = state.chatStore.conversationByIds[sid].conversation.channelState.attributes;
    attributes.isClosed = true;
    console.log(attributes);
    const payload = await updateConversationAttributes(sid, attributes);

    //if (payload.ErrorCode === 401) {
    //    dispatch({ type: setUnauthorizedType, payload });
    //}

    dispatch({ type: closeDeleteChatPopup, renderRedirect: true });

    return payload;
};

const unblockCurrentProvider = async (dispatch, state) => {

    if(!isProviderIdentity(state) || !isCurrentProviderAlreadyBlocked(state)) {
        return;
    }

    const providerId = getCurrentProviderId(state);
    await requestUnblockProvider(providerId);
    dispatch({type: setProviderBlockedStatus, blocked: false});  
    dispatch({type: showUnblockedMessage}); 
}

const hideUnblockProviderMessage = async (dispatch, state) => {
    dispatch({type: hideUnblockedMessage});
}

const requestNetworkStatuses = async (client, dispatch, state) => {

    if (isSubscriberIdentity(state)) {
        const subscriberId = getCurrentSubscriberId(state);
        const providersIds = (await getClientConversations(client))
                             .map(c => c.channelState.attributes.providerId);

        const result = await getSubscriberInviteData(subscriberId, providersIds);
        if (result.ErrorCode) {
            return;
        }       

        dispatch({ type: setNetworkStatusesType, statuses: result });
    }
    else if (isProviderIdentity(state)) {

        const providerId = getCurrentProviderId(state);
        const subscribersIds = (await getClientConversations(client))
                               .map(c => c.channelState.attributes.subscriberId);

        const result = await getProviderInviteData(providerId, subscribersIds);
        if (result.ErrorCode) {
            return;
        }

        dispatch({ type: setNetworkStatusesType, statuses: result });
    }
}

async function getClientConversations(client) {
    let conversations = await client.getSubscribedConversations();
    const itemsAccumulator = conversations.items;

    while (conversations.hasNextPage) {
        conversations = conversations.nextPage();
        itemsAccumulator.push(conversations.items);
    }

    const providersIds = itemsAccumulator;
    return itemsAccumulator;
}
