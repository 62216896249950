export const addConversationType = 'ADD_CONVERSATION';
export const updateConversationType = 'UPDATE_CONVERSATION';
export const removeConversationType = 'REMOVE_CONVERSATION';
export const requestSidType = 'REQUEST_SID';
export const receiveSidType = 'RECEIVE_SID';
export const initChatClientType = 'INIT_CHAT_CLIENT';
export const chatClientInitializedType = 'CHAT_CLIENT_INITIALIZED';
export const chatClientStateChangedType = 'CHAT_CLIENT_STATE_CHANGED';
export const initChatConversationType = 'INIT_CHAT_CONVERSATION';
export const chatConversationInitializedType = 'CHAT_CONVERSATION_INITIALIZED';
export const messageAddedType = 'MESSAGE_ADDED';
export const prepareProviderConversationType = 'PREPARE_PROVIDER_CONVERSATION';
export const setConversationFilterType = 'SET_CONVERSATION_FILTER';
export const setcurrentConversationIdType = 'SET_CURRENT_CONVERSATION_ID';
export const setMessageConsumedTimestampType = 'SET_MESSAGE_CONSUMED_TIMESTAMP_TYPE';
export const setLayoutTimestampType = 'SET_LAYOUT_TIMESTAMP_TYPE';
export const setUnauthorizedType = 'SET_UNAUTHORIZED_TYPE';
export const receiveProviderInNetworkType = 'RECEIVE_PROVIDER_IN_NETWORK_TYPE';
export const resetProviderInNetworkType = 'RESET_PROVIDER_IN_NETWORK_TYPE';
export const setProviderAndSubscriberIds = 'SET_PROVIDER_AND_SUBSCRIBER_IDS';
export const openDeleteChatPopup = 'OPEN_DELETE_CHAT_POPUP';
export const closeDeleteChatPopup = 'CLOSE_DELETE_CHAT_POPUP';
export const confirmChatDelete = 'CONFIRM_CHAT_DELETE';
export const setProviderBlockedStatus = 'SET_PROVIDER_BLOCKED_STATUS';
export const showUnblockedMessage = 'SHOW_UNBLOCKED_MESSAGE';
export const hideUnblockedMessage = 'HIDE_UNBLOCKED_MESSAGE';
export const loadNewMessagesType = 'LOAD_NEW_MESSAGES';
export const setNetworkStatusesType = 'SET_NETWORK_STATUSES';