import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {actionCreators} from '../store/ChatStore';
import {Form, Button} from "antd";
import {getConversationById} from "../store/selectors";
import './assets/SendMessage.css';
import TextareaAutosize from 'react-textarea-autosize';
import SendIcon from "./icons/SendIcon"


class SendMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {newMessage: ""};
        this.onSendMessage = this.onSendMessage.bind(this);
        this.onMessageChanged = this.onMessageChanged.bind(this);
        this.onTextAreaKeyDown = this.onTextAreaKeyDown.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.conversationSid !== this.props.conversationSid) {
            this.setState({newMessage: ""});
        }
    }
    
    render() {

        if(this.props.isAlreadyBlocked)
        {
            return null;
        }

        return this.renderComponent(this.props)
    }
    
    onMessageChanged = event => {
        this.setState({ newMessage: event.target.value });
    };

    onTextAreaKeyDown = event => {
        if (event.ctrlKey && event.key === '\n')
        {
            if (this.state.newMessage)
                this.setState({ newMessage: this.state.newMessage + '\n' });
            event.preventDefault();
        }
        else if (event.key === 'Enter') {
            event.preventDefault();
            this.onSendMessage();
        }

    };
    
    buildMessageBody()
    {
        const pref = this.props.messagePrefix ? this.props.messagePrefix : "";
        return pref + this.state.newMessage;
    }
    
    onSendMessage(event) {
        if (this.state.newMessage) {
            const body = this.buildMessageBody();
            this.setState({newMessage: ""});
            this.props.sendMessage(this.props.conversationSid, body);
        }
    };
    
    renderComponent(props) {
        const newMessage = this.state.newMessage;
        const isButtonDisabled = !props.isReady || !newMessage;

        return (
            <Form onFinish={this.onSendMessage} className={"send_msg_form"}>
                <div className={"send_msg_form_items"}>
                    <TextareaAutosize className={"scroll"}
                        placeholder={"Type Your Message"}
                        name={"message"}
                        id={'type-a-message'}
                        autoComplete={"off"}
                        disabled={!props.isReady}
                        onChange={this.onMessageChanged}
                        value={newMessage}
                        onKeyPress={this.onTextAreaKeyDown}
                        ref={element => this.textArea = element}
                        autoFocus
                        maxRows={9}
                    />
                    <Button htmlType="submit" type={"submit"} disabled={isButtonDisabled} >
                        <div className={"send_icon"}><SendIcon /></div>
                        <div className={"send_text"}>Send</div>
                    </Button>
                </div>
            </Form>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const conversationSid = ownProps.conversationSid;
    const conversation = getConversationById(state, conversationSid);

    return { 
        ...conversation,
        conversationSid: ownProps.conversationSid,
        isAlreadyBlocked: state.chatStore.isAlreadyBlocked
    };
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(SendMessage);
