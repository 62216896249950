import React, {PureComponent} from "react";

export default class FilterIcon extends PureComponent {
  render = () => {
    return (
<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.3088 12.1573L11.5744 9.42297C11.4377 9.3136 11.2736 9.23157 11.1095 9.23157H10.672C11.4103 8.27454 11.8752 7.07141 11.8752 5.73157C11.8752 2.61438 9.30485 0.0440674 6.18766 0.0440674C3.04313 0.0440674 0.500162 2.61438 0.500162 5.73157C0.500162 8.8761 3.04313 11.4191 6.18766 11.4191C7.50016 11.4191 8.70329 10.9816 9.68766 10.2159V10.6808C9.68766 10.8448 9.74235 11.0089 9.87907 11.1456L12.5861 13.8527C12.8595 14.1261 13.2697 14.1261 13.5158 13.8527L14.2814 13.087C14.5548 12.8409 14.5548 12.4308 14.3088 12.1573ZM6.18766 9.23157C4.24626 9.23157 2.68766 7.67297 2.68766 5.73157C2.68766 3.8175 4.24626 2.23157 6.18766 2.23157C8.10172 2.23157 9.68766 3.8175 9.68766 5.73157C9.68766 7.67297 8.10172 9.23157 6.18766 9.23157Z" fill="white"/>
</svg>
    );
  }
}