import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom'

import {actionCreators} from '../store/ChatStore';
import BusyIndicator from "./BusyIndicator";
import ChatHeader from "./ChatHeader";
import SendMessage from "./SendMessage";
import {chatMessageIdentityPrefix, getConversationById, 
        getIdentity, sendMessageIdentityPrefix} from "../store/selectors"
import MessageBubble from "./MessageBubble";
import { redirectToMc } from  '../impl/ChatStoreActions';

import "./assets/Chat.css"

class InitialMessage extends Component {

    constructor(props) {
        super(props);
        this.state = { messagesCount: null };
        this.navigateToConversation = this.navigateToConversation.bind(this);
    }
    
    componentDidMount() {
        this.props.initConversation(this.props.id);
        this.scrollToBottom();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id || !this.props.isReady)
            this.props.initConversation(this.props.id);
        this.scrollToBottom();
    }

    navigateToConversation = event => {
        redirectToMc(this.props.id);
        this.props.updateLayoutTimestamp();
    };
    
    scrollToBottom() {

        if (this.props.messages) {
            const newCount = this.props.messages.length;
            const isScrollInView = this.state.messagesCount !== newCount;

            if (isScrollInView && this.messagesEnd) {
                this.messagesEnd.scrollIntoView();
                this.setState({messagesCount: newCount});
            }
        }
    }
    render() {
        return this.props.isReady
            ? this.renderComponent()
            : this.renderBusyState();
    }

    renderBusyState(){
        return (
            <div className={"messages_container im-container"}>
                <BusyIndicator />
            </div>
        );
    }
    
    renderComponent(props) {
        return (
            <div className={"messages_container im-container"}>
                <ChatHeader headerText={this.props.name} />
                <div className={"scroll initial-message-height"} ref>
                    <div id="messages">
                        {this.renderInitialText(this.props)}
                        <ul>
                            {this.renderMessages(this.props)}
                        </ul>
                        <div style={{float: "left", clear: "both"}}
                             ref={(el) => {
                                 this.messagesEnd = el;
                             }}>
                        </div>
                    </div>
                </div>
                {this.renderMessageSentText(this.props)}
                <SendMessage conversationSid={this.props.conversationSid} messagePrefix={this.props.sendPrefix} />
            </div>
        );
    }
    
    renderInitialText(props) {
        const text = "Typically replies within 24 hours";
        if (this.hasMessageInChat(props))
            return;
        
        return (
            <div className={"initial-message"}>            
                <span > {text} </span>
            </div>
        );
    }

    renderMessageSentText(props) {
        if (!this.hasMessageInChat(props))
            return;

        return (
            <div className={"message-sent"}>
                <div>
                    <span className={"bold"}>Message Sent!</span>
                </div>
                <div className={'message-sent-description'}>
                    <span>The provider has been notified. You can continue your correspondence in the</span>
                </div>
                <div>
                    <Link onClick={this.navigateToConversation} to={""}>messaging center</Link >
                </div>
            </div>
        );
    }
    
    hasMessageInChat(props) {
        return (props.messages && (props.messages.length > 0));
    }
    
    renderMessages(props) {
        if (!props.messages)
            return;
        return (
            props.messages.filter(entry => entry && entry.state.body)
                .map(entry => {
                    const m = entry.state;
                    const d = (m.author === props.identity) ? 'outgoing' : 'incoming';
                    return (<MessageBubble
                        key={m.index} direction={d} message={m}
                        messagePrefix={props.chatMessagePrefix} type={'sms'}/>);
                })
        );
    }
}


function mapStateToProps(state, ownProps) {
    const conversationSid = ownProps.match.params.id;
    const conversation = getConversationById(state, conversationSid);
    const identity = getIdentity(state);
    const chatMessagePrefix = chatMessageIdentityPrefix(state);
    const sendPrefix = sendMessageIdentityPrefix(state);

    return {
        ...conversation, identity: identity,
        conversationSid: conversationSid,
        chatMessagePrefix: chatMessagePrefix,
        sendPrefix: sendPrefix
    };
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(InitialMessage);