import React, { useEffect } from 'react';
import './assets/RecentMessages.css';
import {connect} from "react-redux";
import { chatMessageIdentityPrefix } from "../store/selectors"

const RecentMessages = (props) => {

    const messages = props.conversationData.messages;
    const lastReadIndex = props.conversationData.lastReadIndex ?? -1;
    const unreadMessagesCount = messages.length - lastReadIndex - 1;
    let messagesPreview = '';
    let containerClass = '';

    if (unreadMessagesCount > 1) {
        messagesPreview = `${unreadMessagesCount} messages received`;
        containerClass = 'recent-messages-container container-with-new-messages';
    }
    else if (messages.length > 0) {
        const message = messages[messages.length - 1].state.body;
        messagesPreview = message.replace(props.chatMessagePrefix, '');
        containerClass = unreadMessagesCount ? 'recent-messages-container container-with-new-messages' :
                                               'recent-messages-container';
    }
    else {
        return null;
    }
    
    if(messagesPreview.length > 23) {
        messagesPreview = messagesPreview.substring(0, 20) + '...';
    }

    return (
        <span className={containerClass}>
            <span className={'last-message'}>{messagesPreview}</span>
            <span className={'time-date'}>{'• ' + props.conversationData.dtString}</span>
        </span>
    );
}

function mapStateToProps(state, ownProps) {
    const chatMessagePrefix = chatMessageIdentityPrefix(state);
    return { 
        chatMessagePrefix: chatMessagePrefix
    };
}

export default connect(
    mapStateToProps
)(RecentMessages)
