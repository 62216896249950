import {getLastMessageTimestamp, hasUnreadMessages,
    getLasMessageId
} from '../impl/ChatClientProxy'
import {messagePrefixConst} from "../impl/ChatConsts"

export const getConversationsState = store => store.chatStore;

export const getConversationsList = store =>
    getConversationsState(store) ? getConversationsState(store).conversationIds: [];

export const hasAnyStartedConversation = store => {
    const conversations = getConversationsState(store) ?
        Object.values(getConversationsState(store).conversationByIds) :
        [];
    return conversations.some(c => c.isInitiatedBySubscriber);
}

export const getChatClient = (store, id) => {
    let state = getConversationsState(store);
    if (state) {
        return state.chatClient;
    }
    return {};
};

export const getConversationById = (store, id) => {
    let state = getConversationsState(store);
    if (state) {
        return {...state.conversationByIds[id], id};
    }
    return {};
};

export const getIdentity = store =>
    getConversationsState(store).identity;

export const isProviderIdentity = store => {
    const identity = getIdentity(store);
    return (identity && identity.indexOf('pro') >= 0);
};

export const sendMessageIdentityPrefix = store => 
    messagePrefixConst;

export const chatMessageIdentityPrefix = store =>
    messagePrefixConst;

export const getConversations = store =>
    getConversationsList(store).map(id => getConversationById(store, id));

export const getConversationMessages = (store, id) => {
    let conversation = getConversationById(store, id);
    return conversation.messages;
};

export const getConversationTimestamp = (conversation) => {
    if (conversation && conversation.conversation)
        return getLastMessageTimestamp(conversation.conversation);
    
    return null;
};

export const hasConversationUnreadMessages = (conversation) => {
    if (conversation && conversation.conversation)
        return hasUnreadMessages(conversation.conversation);

    return false;
};

export const getConversationLastMessageId = (conversation) => {
    if (conversation && conversation.conversation) {
        return getLasMessageId(conversation.conversation);
    }
    return null;
};

export const getChatFilter = store => {
    let state = getConversationsState(store);
    if (state) {
        return state.chatFilter;
    }
    return null;
};

export const getChatFilterText = store => {
    let state = getConversationsState(store);
    if (state) {
        return state.chatFilterText;
    }
    return null;
};

export const getFilteredConversationIds = store => {
    let state = getConversationsState(store);
    if (state) {
        let ids = [...state.conversationIds];
        let filter = getChatFilter(store);
        if (filter) {
            ids = ids.filter(id => {
                const conversation = getConversationById(store, id);
                return filter(conversation);
            })
        }
        ids.sort((id1, id2) => {
            const conversation1 = getConversationById(store, id1);
            const conversation2 = getConversationById(store, id2);
            let ts1 =  getConversationTimestamp(conversation1);
            let ts2 =  getConversationTimestamp(conversation2);
            if (!ts1)
                ts1 = 0;
            if (!ts2)
                ts2 = 0;
            return ts2 - ts1;
        });
        return ids;
    }
    return null;
};

export const getCurrentConversationId = store => {
    let state = getConversationsState(store);
    if (state) {
        return state.currentConversationId;
    }
    return null;
};

export const getMessageConsumedTimestamp = store => {
    let state = getConversationsState(store);
    if (state) {
        return state.messageConsumedTimestamp;
    }
    return null;
};

export const getCurrentSubscriberId = store => {
    const subscriberIdRegExp = /(?:sub)([\d]+)/i;
    const identity = getIdentity(store)
    const match = identity.match(subscriberIdRegExp);
    if (match)
        return parseInt(match[1]);
    return 0;
};

export const getCurrentProviderId = store => {
    const subscriberIdRegExp = /(?:pro)([\d]+)/i;
    const identity = getIdentity(store)
    const match = identity.match(subscriberIdRegExp);
    if (match)
        return parseInt(match[1]);
    return 0;
};

/**
 * Check if user is subscriber.
 * @param {any} store
 */
export const isSubscriberIdentity = store => {
    const identity = getIdentity(store);
    return (identity && identity.indexOf('sub') >= 0);
}

/**
 * Check if provider was already blocked.
 * @param {any} store
 */
export const isCurrentProviderAlreadyBlocked = store => {    
    return getConversationsState(store).isAlreadyBlocked;
}